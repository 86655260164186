import styled from '@emotion/styled';
import { COLORS, SIZE } from '../../config';

export const Page = styled.div`

  .preloader {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .underline {
    text-decoration: underline;
  }

  .shadow {
    box-shadow: 0px 20px 50px rgba(0,0,0,0.1);
  }

  .no-flex {
    flex: none;
  }

  .content-container {
    max-width: 1400px;
    min-height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: ${SIZE.LARGE}px;
  }

  a {
    color: inherit;
    text-decoration: none;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  a:hover {
    color: ${COLORS.YELLOW};
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }

`;
