import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './components/app-container';
import Routes from './routes';

ReactDOM.render(
	<React.StrictMode>
		<AppContainer>
			<Routes />
		</AppContainer>
	</React.StrictMode>,
	document.getElementById('root')
);
