import styled from '@emotion/styled';
import { SIZE, FONTS, COLORS } from '../../config';
import { ITextProps } from './'

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${(props: ITextProps) => 
      props.size === 'body' ? FONTS.BODY :
      props.size === 'small' ? FONTS.SMALL :
      props.size === 'title' ? FONTS.TITLE :
      props.size === 'xl' ? FONTS.LARGE : 
      props.size === 'xxl' ? FONTS.XXL : 
      FONTS.BODY}px;
  line-height: ${(props: ITextProps) => 
      props.size === 'body' ? FONTS.LINE_HEIGHT.BODY :
      props.size === 'small' ? FONTS.LINE_HEIGHT.SMALL :
      props.size === 'title' ? FONTS.LINE_HEIGHT.TITLE :
      props.size === 'xl' ? FONTS.LINE_HEIGHT.LARGE : 
      props.size === 'xxl' ? FONTS.XXL : 
      FONTS.LINE_HEIGHT.BODY}px;
  font-style: ${(props: ITextProps) => props.fontStyle ? props.fontStyle : 'none'};
  color: ${(props: ITextProps) => props.color ? props.color : COLORS.DARK};
`;
