import { Button } from './styles';

interface IProps {
	title: string;
	href?: string;
	onPress?: (event: any) => void;
	disabled?: boolean;
	size?: 'default' | 'small' | 'large';
	theme?: 'default' | 'secondary';
	type?: 'button' | 'submit' | 'reset';
}

const ButtonComponent = (props: IProps) => {
	if (props.onPress)
		return (
			<Button
				disabled={props.disabled || false}
				size={props.size}
				onClick={props.onPress}
				className='courier'>
				{props.title}
			</Button>
		);

	return (
		<Button
			disabled={props.disabled || false}
			size={props.size}
			type={props.type || 'button'}
			className='courier'>
			{props.title}
		</Button>
	);
};

export default ButtonComponent;
