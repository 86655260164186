import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Global, css } from '@emotion/react';
import { Block, Preloader } from '../../components'
import { Page } from './styles';

const {
	REACT_APP_FIREBASE_APIKEY,
	REACT_APP_FIREBASE_AUTHDOMAIN,
	REACT_APP_FIREBASE_DBURL,
	REACT_APP_FIREBASE_PRJID,
	REACT_APP_FIREBASE_BUCKET,
	REACT_APP_FIREBASE_MSGSENDID,
	REACT_APP_FIREBASE_APPID,
	REACT_APP_FIREBASE_MEASUREID,
  REACT_APP_TEST_USER,
  REACT_APP_TEST_PW
} = process.env;

const firebaseConfig = {
	apiKey: REACT_APP_FIREBASE_APIKEY,
	authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
	databaseURL: REACT_APP_FIREBASE_DBURL,
	projectId: REACT_APP_FIREBASE_PRJID,
	storageBucket: REACT_APP_FIREBASE_BUCKET,
	messagingSenderId: REACT_APP_FIREBASE_MSGSENDID,
	appId: REACT_APP_FIREBASE_APPID,
	measurementId: REACT_APP_FIREBASE_MEASUREID
};

interface IProps {
	children: React.ReactNode;
}

const AppContainer = (props: IProps) => {
	const [loading, setLoading] = useState(true);
  const DEV = process.env.NODE_ENV !== 'production';

  useEffect(() => {
		/* Initialize Firebase, the condition check fixes hotreloading issues */
		if (typeof window !== 'undefined' && !firebase.apps.length) {
			firebase.initializeApp(firebaseConfig);
			/* Sign in the User so they can have write access */
			firebase
				.auth()
        .signInWithEmailAndPassword(
          REACT_APP_TEST_USER || '',
          REACT_APP_TEST_PW || ''
        )
				.then(user => {
					// Signed in..
					DEV && console.log('user signin', user);
					setLoading(false);
				})
				.catch(error => {
					console.log('error on auth', error);
				});
			//@ts-ignore
			window.firebase = firebase;
    }
    //@ts-ignore
		// setDbCollection(firebase.database().ref('windscreen'));
		DEV && console.log('.env values', process.env);
	}, []);

	useEffect(() => {
		console.log('Auth');
	}, []);

	return (
    <>
		<Global
			styles={css`
				html,
				body {
					padding: 0;
					margin: 0;
					font-family: 'courier', -apple-system, BlinkMacSystemFont, Segoe UI,
						Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
						Helvetica Neue, sans-serif;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					overflow-x: hidden;
				}
			`}/>
      <Page>
        {!loading ? props.children : 
				<Block className='preloader'>
					<Preloader />
				</Block>
				}
      </Page>
		</>
	);
};

export default AppContainer;
