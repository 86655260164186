import styled from '@emotion/styled';
import { SIZE, COLORS, FONTS } from '../../config';

export const Feature = styled.div`
	width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${COLORS.DARK};
`;
