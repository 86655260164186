import { Preloader } from './styles';

export interface IPreloaderProps {
	color?: string;
}

const PreloaderComponent = (props: IPreloaderProps) => {
	return (
		<Preloader color={props.color}>
			<div className='spinner'>
				<div className='bounce1' />
				<div className='bounce2' />
				<div className='bounce3' />
			</div>
		</Preloader>
	);
};

export default PreloaderComponent;
