import styled from '@emotion/styled';
import { COLORS, SIZE } from '../../config';

export const Page = styled.div`
  background-color: ${COLORS.YELLOW};
  .confetti {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }
  .scanner {
    width: 100%;
    height: 100%;
  }
`
export const Logo = styled.div`
  width: 100%;
  text-align: center;
  padding: ${SIZE.MEDIUM}px;
  img {
    max-width: 300px;
  }
`