import React from 'react';
import { Block } from './styles';

export interface IBlockProps {
	ref?: any;
	w?: number;
	h?: number;
	p?: number;
	pt?: number;
	pb?: number;
	pl?: number;
	pr?: number;
	m?: number;
	mt?: number;
	mb?: number;
	ml?: number;
	mr?: number;
	f?: number;
	flx?: 'row' | 'column';
	justify?:
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around';
	align?:
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around';
	style?: any;
	children?: React.ReactNode;
	className?: string;
	onPress?: (event: any) => void;
}

const BlockComponent = React.forwardRef((props: IBlockProps, ref) => {
	const { children, style, className, onPress, ...rest } = props;
	return (
		<Block
			ref={ref}
			style={style}
			{...rest}
			onClick={onPress}
			className={className}>
			{props.children}
		</Block>
	);
});

export default BlockComponent;
