import { useEffect } from 'react';
// import Logo from '../../assets/images/icon-text.png';

interface IProps {}

const AuthScreen = (props: IProps) => {
	useEffect(() => {
		console.log('Auth');
	}, []);

	return (
		<div className='content-container'>
			{/* <img src={Logo} className='App-logo' alt='SAVI' /> */}
			<p>auth login</p>
		</div>
	);
};

export default AuthScreen;
