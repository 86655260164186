export const CURRENCY = 'au'
// Set your amount limits: Use float for decimal currencies and
// Integer for zero-decimal currencies: https://stripe.com/docs/currencies#zero-decimal.
export const MIN_AMOUNT = 10.0
export const MAX_AMOUNT = 5000.0
export const AMOUNT_STEP = 5.0

export const COLORS = {
  DARK: "#252525",
  LIGHT: "#fff",
  YELLOW: "#F8da03",
  YELLOW_DARK: "#b3a122",
  GREY: "#C3C3C3",
  GREY_LIGHT: "#f3f3f3"
}

export const FONTS = {
  XS: 8,
  SMALL: 10,
  BODY: 16,
  TITLE: 24,
  LARGE: 32,
  XXL: 48,
  XXXL: 64,
  LINE_HEIGHT: {
    XS: 10,
    SMALL: 14,
    BODY: 26,
    TITLE: 32,
    LARGE: 38,
    XXL: 48,
    XXXL: 64,
  }
}

export const SIZE = {
  XS: 4,
  SMALL: 8,
  MEDIUM: 16,
  LARGE: 32,
  XL: 48,
  XXL: 64,
  XXXL: 88,
  HEADER: 120,
}


export const BREAKPOINTS = {
  MOBILEXS: 320,
  MOBILE: 365,
  MOBILEXL: 640,
  TABLET: 720,
  DESKTOP: 1024,
  MENU: 800
}