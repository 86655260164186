import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthScreen from './screens/auth';
import DashScreen from './screens/dashboard';
import ScanScreen from './screens/scan';
// import { Menu } from './components';

export default function App () {
	return (
		<Router>
			<>
				{/* <Menu /> */}
				<Switch>
					<Route path='/auth'>
						<AuthScreen />
					</Route>
					<Route path='/scan'>
						<ScanScreen />
					</Route>
					<Route path='/dashboard'>
						<DashScreen />
					</Route>
					<Route path='/'>
						<ScanScreen />
					</Route>
				</Switch>
			</>
		</Router>
	);
}
