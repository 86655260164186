// @ts-nocheck
import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import QrReader from 'react-qr-reader';
import Confetti from 'react-confetti'
import { Block, Button } from '../../components'
import { Logo, Page } from './styles';
import { SIZE, COLORS } from '../../config';

interface IProps {}

const DashScreen = (props: IProps) => {
	const [ qrResult, setQrResult ] = useState(null);
	const [ showScanner, setShowScanner ] = useState(true);
	const [ database, setDatabase ] = useState(null);
	const [ processing, setProcessing ] = useState(false);
	const [ allreadyUsed, setAllreadyused ] = useState(false);
	const [ voucherReceiptDetails, setVoucherReceiptDetails ] = useState(null);
	const DEV = process.env.NODE_ENV !== 'production';

	useEffect(() => {
		setFbDatabaseRef();
		DEV && console.log('.env values', process.env);
	}, []);

	const setFbDatabaseRef = () => {
		if (firebase.apps.length > 0) {
			console.log('set db')
			!database && setDatabase(firebase.database().ref('receipts'));
		}
	}

	const getVoucherDetails = async (pId: string) => {
    let value = null;
    database && await database.orderByChild("pId")
      .equalTo(`pi_${pId}`)
      .once('value')
      .then((snapshot) => {
        console.log('snapshot', snapshot.val());
        value = snapshot.val();
        return value;
      })
      .catch((err) => {
        console.log('snapshot error', err);
        return null;
      })
		
		return value
	}

	const updateVerifyRecord = async (pId: string) => {
		const updateRecord = await database
			.orderByChild("pId")
      .equalTo(`pi_${pId}`)
      .once('value', function (sn) {
				sn.forEach(async (child) => {
					await child.ref.update({
						verified: true,
						verified_date: new Date().toISOString()
					});
				});
			})
      .then(async (snapshot) => {
        return snapshot.val();
      })
      .catch((err) => {
        console.log('snapshot error', err);
        return null;
      })

			console.log('Updated record', updateRecord);
	}

	const handleScan = async qrData => {
		if (qrData) {
			// alert(qrData)
			setQrResult(qrData);
			setShowScanner(false);
			if(!processing) {
				setProcessing(true);
				/* Fetch the Receipt details */
				const receiptResponse = await getVoucherDetails(qrData);
				const receiptKey = Object.keys(receiptResponse);
				const receiptDetails = receiptResponse[receiptKey[0]];
				setVoucherReceiptDetails(receiptDetails);
				console.log('receiptDetails.verified', receiptDetails.verified)
				if(!receiptDetails.verified) {
					updateVerifyRecord(qrData)
				} else {
					setAllreadyused(true);
				}
			}
			
			setShowScanner(false);
			console.log('receiptDetails', receiptDetails);
		}
	};

	const handleError = err => {
		console.error(err);
	};

	const onRescan = () => {
		setQrResult(null);
		setShowScanner(true);
		setAllreadyused(false);
		setVoucherReceiptDetails(null);
		setProcessing(false);
	}

	return (
		<Page className='content-container'>
			<Logo>
				<img
					src={'/images/createart.svg'}
					className='scan-logo'
					alt='create art'
				/>
			</Logo>
			<>
			{showScanner && <p>scan QR to verifiy voucher</p> }
			<div className='scanner' style={{display: showScanner ? 'block' : 'none'}}>
				<QrReader
					delay={300}
					onError={handleError}
					onScan={handleScan}
					style={{ width: '100%' }}
				/>
			</div>
			</>
			<>
				{!showScanner && voucherReceiptDetails &&
				<Block w={window.innerWidth-100} p={SIZE.LARGE} className='no-flex'>
					{allreadyUsed ? <h1>Voucher has already been used</h1> : <>
						<Block className='confetti'>
						<Confetti
							colors={[COLORS.LIGHT, COLORS.YELLOW_DARK, COLORS.DARK, COLORS.GREY_LIGHT, COLORS.GREY_LIGHT]}
							width={window.innerWidth}
							height={window.innerHeight}
						/>
					</Block>
						<h1>Voucher Verified! 🥳 </h1>
					</>}
				
					<Block mt={SIZE.LARGE}>
						<Button title='scan again' onPress={onRescan}/>
					</Block>
				</Block>
			}
			</>
		</Page>
	);
};

export default DashScreen;
