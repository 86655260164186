import styled from '@emotion/styled';
import { IBlockProps } from '.';

export const Block = styled.div`
	width: ${(props: IBlockProps) => props.w ? `${props.w}px` : `100%`};
  height: ${(props: IBlockProps) => props.h ? `${props.h}px` :`auto`};
  display: flex;
  flex: ${(props: IBlockProps) => props.f || 1};
  flex-direction: ${(props: IBlockProps) => props.flx || `column`};
  justify-content: ${(props: IBlockProps) => props.justify || `flex-start`};
  align-items: ${(props: IBlockProps) => props.align || `flex-start`};
  padding: ${(props: IBlockProps) => props.p ? `${props.p}px` : 'auto'};
  margin: ${(props: IBlockProps) => props.m ? `${props.m}px` : '0'};
  padding-top: ${(props: IBlockProps) => props.pt ? `${props.pt}px` : 'auto'};
  padding-bottom: ${(props: IBlockProps) => props.pb ? `${props.pb}px` : 'auto'};
  padding-left: ${(props: IBlockProps) => props.pl ? `${props.pl}px` : 'auto'};
  padding-right: ${(props: IBlockProps) => props.pr ? `${props.pr}px` : 'auto'};
  margin-top: ${(props: IBlockProps) => props.mt ? `${props.mt}px` : '0'};
  margin-bottom: ${(props: IBlockProps) => props.mb ? `${props.mb}px` : '0'};
  margin-left: ${(props: IBlockProps) => props.ml ? `${props.ml}px` : '0'};
  margin-right: ${(props: IBlockProps) => props.mr ? `${props.mr}px` : '0'};
`;
