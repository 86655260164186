import styled from '@emotion/styled';
import { SIZE, FONTS, COLORS, BREAKPOINTS } from '../../config';
import { INavItemProps } from './'

export const NavItem = styled.div`
  font-size: ${FONTS.TITLE}px;
  padding: ${SIZE.SMALL}px ${SIZE.SMALL}px;
  color: ${(props: INavItemProps) => props.darkTheme? COLORS.LIGHT : !props.active ? COLORS.LIGHT : COLORS.YELLOW_DARK};
  cursor: pointer;
  border-radius: 4px;
  opacity: ${(props: INavItemProps) => props.active ? 0.75 : 1};
  pointer-events: ${(props: INavItemProps) => props.active ? 'none' : 'auto'};
  transition: background .15s ease;
  
  &:hover {
    background-color: ${(props: INavItemProps) => props.darkTheme? COLORS.YELLOW_DARK : COLORS.YELLOW};
  }

  @media (min-width: ${BREAKPOINTS.MENU}px) {
      color: ${(props: INavItemProps) => props.darkTheme? COLORS.LIGHT : !props.active ? COLORS.DARK : COLORS.YELLOW_DARK};

  }
`;
