import styled from '@emotion/styled';
import { SIZE, FONTS, COLORS, BREAKPOINTS } from '../../config';

export const Footer = styled.div`
	width: 100%;
  font-size: ${FONTS.SMALL}px;
  /* border-top: 0.5px solid rgba(0,0,0,0.15); */
  /* padding-top: ${SIZE.LARGE}px; */
  padding-bottom: ${SIZE.SMALL}px;
  margin-bottom: ${SIZE.XL}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${COLORS.DARK};
  position: relative;
  background-color: ${COLORS.LIGHT};
  border-radius: ${SIZE.SMALL}px;

  a {
    font-weight: bold;
  }
  a:hover {
     color: ${COLORS.YELLOW};
     text-decoration: underline;
  }

  .nsw-provider {
    width: 150px;
    margin: ${SIZE.MEDIUM}px 0;
    img {
      width: 100%;
    }
  }

  .footer-social {
    width: 25px;
    display: contents;
    a { padding: ${SIZE.MEDIUM}px }
    img {
      width: 25px;
      transition: transform .05s ease;
      &:hover {
        transform:scale(1.1);
      }
    }
  }
  
  .site-link {
    &:hover {
      .deprogram {
        color: green;
        text-decoration: underline;
        text-underline-offset: -5px;
        text-decoration-thickness: 0.25em;
      }
    }
  }
  @media(min-width: ${BREAKPOINTS.MENU}px) {
    .nsw-provider {
      width: 150px;
      position: absolute;
      right: ${SIZE.LARGE}px;
      bottom: ${20}px;
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
`;
