import styled from '@emotion/styled';
import { SIZE, FONTS, COLORS } from '../../config';

interface IButton {
	disabled: boolean;
	size?: 'default' | 'small' | 'large';
}

export const Button = styled.button`
	padding:  ${(props: IButton) => !props.size || props.size ==='default' ? `${SIZE.MEDIUM}px ${SIZE.LARGE}px` :
	props.size ==='small' ? `${SIZE.MEDIUM}px ${SIZE.MEDIUM}px` : `${SIZE.MEDIUM}px ${SIZE.LARGE}px`};
	background-color: ${COLORS.DARK};
	font-size: ${(props: IButton) => !props.size || props.size ==='default' ? FONTS.TITLE :
	props.size ==='small' ? FONTS.BODY : FONTS.LARGE}px;
  letter-spacing: 2px;
	border-radius: 4px;
	color: ${COLORS.LIGHT};
	border: 0;
	border: 2px solid ${COLORS.DARK};
  outline: none;
	cursor: pointer;
	opacity: ${(props:IButton) => props.disabled ? 0.25 : 1};
	pointer-events: ${(props:IButton) => props.disabled ? 'none' : 'auto'};
	transition: background .25s ease, transform .15s ease;

	&:hover {
		color: ${COLORS.DARK};
    background-color: ${COLORS.YELLOW};;
		border: 2px solid ${COLORS.DARK};
		transform:scale(1.055);
	}
`;
